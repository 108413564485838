import { styled } from '@stitches/react';

type FormSubmittedProps = {
  title: string;
  message: string;
};

function FormSubmitted({ title, message }: FormSubmittedProps) {
  return (
    <Root role="alert">
      <FormSubmittedHeader>{title}</FormSubmittedHeader>
      <p>{message}</p>
    </Root>
  );
}

const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$surfaceVariant',
  color: '$onSurface',
  fontSize: '$fontSize200',
  padding: '$s300',
  my: '$s200',
  '& p': {
    textAlign: 'center',
  },
  '@media (max-width: 720px) ': {
    marginLeft: '16px',
    marginRight: '16px',
  },
});

const FormSubmittedHeader = styled('p', {
  fontSize: '$fontSize500',
  fontFamily: 'fontBold',
  paddingBottom: '$s75',
});

export default FormSubmitted;
